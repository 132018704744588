import { Vue, Component, Prop } from 'vue-property-decorator';
import UIkit from 'uikit';
import { ScreenText } from '@/lang/ScreenText';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import MultiSelectDropdown from '@/ui-components/multiSelectDropdown/MultiSelectDropdown.vue';
import DropdownList from '@/ui-components/dropdownListBx/DropdownList.vue';

@Component({
  components: {
    'progress-button': ProgressButton,
    'datepicker': DatepickerComponent,
    'multi-select-dropdown': MultiSelectDropdown,
    'dropdown': DropdownList,

  },
})
export default class DeleteModalComponent extends Vue {
  @Prop({default: ''})
  component!: string;

  @Prop({default: ''})
  buttonActionLabel!: string;

  private objScreenText: ScreenText = new ScreenText();

  destroyed(){
    this.close();
  }

  close() {
    if (UIkit.modal('#delete-popup')) {
      UIkit.modal('#delete-popup').hide();
    }
    const popupDisplay = <any>document.getElementsByTagName('html');
    popupDisplay[0].classList.remove('uk-modal-page');
  }


  public getScreenText(key: string): string {
    return this.objScreenText.getScreenText(key);
  }

  emitEvent(){
    this.$emit('deleteContactEvent', true);
    this.close();
  }
}
