



























































import DeleteModalComponent from './DeleteModal';
export default DeleteModalComponent;
